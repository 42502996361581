<template>
  <div style="padding: 0 10px; position: relative; background: white; height: 100%;">
    <div class="nav">
      <van-icon
        class="ge-van-icon"
        name="arrow-left"
        size="20"
        @click="goBack"
      />
      <div class="btnGroup">
        <van-button
         :color="backgroundColor == 1 ? 'linear-gradient(to right, #ff7000, #ff3d00)' : '#f1f1f1'"
         round :style="{height: '24px', color: fontColor == 1 ? 'white' : 'black'}"
         @click="clickBtn(1)">科目1</van-button>
        <van-button
         :color="backgroundColor == 4 ? 'linear-gradient(to right, #ff7000, #ff3d00)' : '#f1f1f1'"
         round :style="{height: '24px', color: fontColor == 4 ? 'white' : 'black'}"
         @click="clickBtn(4)">科目4</van-button>
      </div>
    </div>
    <!-- 科目1内容 -->
    <div class="content">
      <p style="font-weight: bold;">选择题</p>
      <div class="choseContent">
        <p style="font-size: 13px;">{{ question.question }}</p>
        <img :src="question.url" alt="">
        <div class="ABCD">
          <!-- 选项 -->
          <div>
            <p v-if="question.item1" :style="{color:answer==1||result.includes(1)?'#6fbd19':''}">A：{{ question.item1 }}</p>
            <p v-if="question.item2" :style="{color:answer==2||result.includes(2)?'#6fbd19':''}">B：{{ question.item2 }}</p>
            <p v-if="question.item3" :style="{color:answer==3||result.includes(3)?'#6fbd19':''}">C：{{ question.item3 }}</p>
            <p v-if="question.item4" :style="{color:answer==4||result.includes(4)?'#6fbd19':''}">D：{{ question.item4 }}</p>
          </div>
          <!-- /选项 -->
        </div>

        <!-- 解释 -->
        <div class="answerExplain" style="margin-top: 25px;">
          <div>
            <span>答案解释：</span>
            <span v-html="question.explains"></span>
          </div>
        </div>
        <!-- /解释 -->
      </div>
      </div>
    <!-- 科目1内容 -->
      <div class="btnBox">
        <div class="bottom-Btn">
          <van-button
            round
            style="width: 162px; height: 36px;"
            color="linear-gradient(to right, #ff7000, #ff3d00)"
            :disabled="
              num == 0 && fontColor==1 || subjectNum4 == 0 && fontColor==4? true: false
            "
            @click="preQuestion"
          >上一题</van-button>
          <van-button
            round
            style="width: 162px; height: 36px;"
            color="linear-gradient(to right, #ff7000, #ff3d00)"
            @click="nextQuestion"
            :disabled="num==total-1 && fontColor==1 || subjectNum4 == total-1 && fontColor==4 ? true: false"
          >下一题</van-button>
        </div>
      </div>
   
  </div>
</template>

<script>
import { fetchQuestion, fetchAnswer } from '@/api/drivingCalendar'
  export default {
    data () {
      return {
        backgroundColor: 1,
        fontColor: 1,
        choseColor: 0,
        questionsAll: [],
        question:{},
        subjectNum4uestion:{},
        total:0,
        num: 0,
        subjectNum4: 0,
        disabled: false,
        answer: '',
        result: [],
      }
    },
    created () {
      // this.$store.commit('setSession', 'b0dc0f25-367b-4d74-96cf-492b9a52d7be')
      this.fetchQuestion()
      this.fetchAnswer()
    },
    methods: {
      clickBtn (num) {
        this.backgroundColor = num
        this.fontColor = num
        this.fetchQuestion()
      },

      // 单选框
      clickRadio (num) {
        console.log(num, 2341)
      },
      
      // 请求题库
      fetchQuestion () {
        fetchQuestion({
          subject: this.fontColor,
          model: 'c1',
          testType: 'rand'
        }).then(res => {
          // console.log(res, '题库')
          if (res.data.code === 0) {
            this.questionsAll = res.data.data.result
            this.total = this.questionsAll.length
            if (this.fontColor === 1) {
              this.question = this.questionsAll[this.num]
            } else {
              this.question = this.questionsAll[this.subjectNum4]
              this.subjectNum4uestion = this.questionsAll[this.subjectNum4]
            }
            console.log(this.question, this.total, 'questions')
            this.answer = this.question.answer
            this.result = this.question.answer
            console.log(this.answer, this.result, 'answer')
            if (this.answer == 7) {
              this.result = [1, 2]
            } else if (this.answer == 8) {
              this.result = [1, 3]
            } else if (this.answer == 9) {
              this.result = [1, 4]
            }else if (this.answer == 10) {
              this.result = [2, 3]
            }else if (this.answer == 11) {
              this.result = [2, 3]
            }else if (this.answer == 12) {
              this.result = [3, 4]
            }else if (this.answer == 13) {
              this.result = [1, 2, 3]
            }else if (this.answer == 14) {
              this.result = [1, 2, 4]
            }else if (this.answer == 15) {
              this.result = [1, 3, 4]
            }else if (this.answer == 16) {
              this.result = [2, 3, 4]
            }else if (this.answer == 17) {
              this.result = [1, 2, 3, 4]
            }
          }
        })
      },

      // 请求答案
      fetchAnswer () {
        fetchAnswer().then(res => {
          console.log(res, '答案')
        })
      },
      
      // 上一题
      preQuestion () {
        if (this.fontColor === 1) {
          this.num--
        } else {
          this.subjectNum4--
        }
        this.fetchQuestion()
      },
      // 下一题
      nextQuestion () {
        if (this.fontColor === 1) {
          this.num++
        } else {
          this.subjectNum4++
        }
        this.fetchQuestion()
      },

      // 回退
      goBack () {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isIOS) {
          window.webkit.messageHandlers.close.postMessage('up');   
        }
        if (isAndroid) {
          window.android.close(); 
        } 
      }
    }
  }
</script>

<style scoped>
.nav {
  width: 100%;
  position: relative;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
}
.nav .ge-van-icon {
  position: absolute;
  left: 10px;
  top: 11px;
}
.nav .btnGroup {
  margin: auto;
  background: #f1f1f1;
  width: 156px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
}
.nav .van-button {
  width: 78px;
  border: none;
}
.content {
  padding: 44px 6px 0 6px;
}
.ABCD {
  margin-top: 30px;
}
.btnBox {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
}
.bottom-Btn {
  display: flex;
  margin: auto;
  width: 332px;
  justify-content: space-between;
}
.choseContent {
  min-height: 530px;
  margin-bottom: 10px;
}
.van-radio,
.van-checkbox {
  margin-bottom: 5px;
}
</style>